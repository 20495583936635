<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <pharmacy-list></pharmacy-list>
    </v-col>
  </v-row>
</template>

<script>
import pharmacyList from '@/components/tables/pharmacyList.vue'

export default {
    components: {
        pharmacyList,
    },
    setup() {
        return {}
    },
}
</script>
